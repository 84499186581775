.invoice-container {
    
    margin: auto;
    
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
 

    background-color: #fff;
  }

  .field{text-align: left;}
  
  .invoice-header, .invoice-footer, .invoice-table, .invoice-summary {
    margin-bottom: 20px;
  }
  
  .invoice-table th, .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Additional styling for layout, fonts, and colors */
  